import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LegalQuotesIntro'

// markup
const LatQuotes_s = () => {
  return (
   <Layout >
      <Head title='Latin Legal Maxims, Writs and Expressions - S'/>
      <h1>Legal Latin maxims and expressions</h1>
     <h2>S</h2>
     <LatQuotesIntro />
     <p><b>Salus populi est suprema lex</b> - The safety of the people is the supreme law.</p>
<p><b>Sciens</b> - Knowingly.</p>
<p><b>Scienter</b> - Knowingly.</p>
<p><b>Scire facias</b> - That you cause to know.</p>
<p><b>Scribere est agere</b> - To write is to act.</p>
<p><b>Se defendendo</b> - In self defence.</p>
<p><b>Secus</b> - The legal position is different, it is otherwise.</p>
<p><b>Semper praesumitur pro legitimatione puerorum</b> - Everything is presumed in favor of the legitimacy of children.</p>
<p><b>Semper pro matriomonio praesumitur</b> - It is always presumed in favor of marriage.</p>
<p><b>Sententia interlocutoria revocari potest, definitiva non potest</b> - An interlocutory order can be revoked, a final order cannot be.</p>
<p><b>Servitia personalia sequuntur personam</b> - Personal services follow the person.</p>
<p><b>Sic utere tuo ut alienum non laedas</b> - So use your own as not to injure another s property.</p>
<p><b>Simplex commendatio non obligat</b> - A simple recommendation does not bind.</p>
<p><b>Stare decisis</b> - To stand by decisions (precedents).</p>
<p><b>Stet</b> - Do not delete, let it stand.</p>
<p><b>Sub modo</b> - Within limits.</p>
<p><b>Sub nomine</b> - Under the name of.</p>
<p><b>Sub silentio</b> - In silence.</p>
<p><b>Sublata causa, tollitur effectus</b> - The cause being removed, the effect ceases.</p>
<p><b>Sublato fundamento, cadit opus</b> - The foundation being removed, the structure falls.</p>
<p><b>Subsequens matrimonium tollit peccatum praecedens</b> - A subsequent marriage removes the preceding wrong.</p>
<p><b>Suggestio falsi</b> - The suggestion of something which is untrue.</p>
<p><b>Sui generis</b> - Unique.</p>
<p><b>Summa ratio est quae pro religione facit</b> - The highest reason is that which makes for religion, i.e. religion dictates.</p>
<p><b>Suppressio veri</b> - The suppression of the truth.</p>
<p><b>Suppressio veri expressio falsi</b> - A suppression of truth is equivalent to an expression of falsehood.</p>

   </Layout>
  )
}

export default LatQuotes_s
